import { defineStore } from 'pinia';

export const useNewsStore = defineStore('news', {
  state: () => ({ articles: [] }),

  actions: {
    setArticles(articles) {
      this.articles.push(...articles);
    },

    async fetchArticle(payload = {}) {
      const { $api } = useNuxtApp();
      const { idOrSlug } = payload;

      const url = `/news/${idOrSlug}/content`;
      const { news, recent_news } = await $api.get(url);

      return {
        article: news,
        recentNewsArticles: recent_news,
      };
    },

    async fetchArticles(payload = {}) {
      const { $api } = useNuxtApp();
      const {
        category,
        limit = 20,
        reset = false,
        skip = 0,
        saveToStore = true,
      } = payload;

      const params = { limit, skip };

      if (category) {
        params.category = category;
      }

      const { news } = await $api.get('/news', params);

      if (saveToStore === false) {
        return { news };
      }

      if (news?.length) {
        if (reset) {
          this.articles = news;
        }
        else {
          this.setArticles(news);
        }
      }

      return news?.length;
    },

    resetArticles() {
      this.articles = [];
    },
  },
});
